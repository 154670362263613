.header {
  height: 78px;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 1;
  border-bottom: 1px solid #dee2e6;

  &Navbar {
    height: 100%;
    width: fit-content;
    margin: auto;

    &Link {
      display: inline-block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin: 1rem 1.5rem;
      text-transform: uppercase;
      color: rgba(0, 0, 0, .9) !important;
      font-weight: 500;

      &:hover {
        display: inline-block;
        padding-bottom: 0;
        border-bottom: 3px solid #796094;
        transition: all .1s;
      }
      &Infoland {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
        width: 172px;
        text-transform: uppercase;
        margin-top: 11px;
        height: 50px;
        padding-top: 11px;
        padding-bottom: 11px;
        background: #333;
        color: white !important;
        vertical-align: middle;
        &:hover {
          color: #ced4da !important;
        }
      }
    }
  }

  &Logo {
    &Img {
      width: 150px;
      height: 30px;
    }
  }
}
