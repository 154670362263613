.container {
  background: #F2F2F2;
  height: 100vh;
  width: 100%;
  position: relative;
}

.content {
  display: flex;
  position: relative;
  height: 80%;
  width: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2em;
  top: 50px;
}
