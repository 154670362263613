.footer {
  text-align: left;
  color: white;
  padding: 60px 0;
  background: #796094;
  width: 100%;

  &Logo {
    &Img {
      width: 150px;
    }
  }
  &Link {
    color: white;
    text-decoration: underline;
    &:hover {
      color: lightgray;
      text-decoration: none;
    }
  }
}

.social {
  display: block;
  margin-bottom: 20px;
  text-decoration: none;
  transition: all 1s;

  &:hover > &Icon {
    animation: rotateIcon .4s ease;
    transform-origin: 50% 0;
  }

  &Icon {
    animation: rotateInitial .4s ease;
    font-size: 40px;
    padding-right: 10px;
    vertical-align: middle;
    padding-top: 5px;
  }

  &Text {
    color: white;
    font-size: 20px;
    padding-left: 5px;

    &:before, &:hover {
      color: lightgray;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

@keyframes rotateInitial {
  100% {
    transform: rotateY(0deg);
  }
  0% {
    transform: rotateY(360deg);
  }
}

@keyframes rotateIcon {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
