.container {
  width: auto;
  margin: 0 auto;
}

.loginForm {
  position: relative;
  height: 470px;
  margin: 50px 80px;
  padding: 50px;
  text-align: left;
  font-size: 1.125rem;
  background: white;
  box-shadow: 0 0 20px rgba(0,0,0,.1);
  width: 600px;
  color: black;
  font-weight: 500;

  &SubmitFormSection{
    display: inline-block;
    flex-direction: row;
    align-items: center;
  }

  &Infotext {
    font-size: 1.125rem;
    text-align: left;
  }

  &Errortext {
    display: block;
    margin-top: 10px;
    pointer-events: none;
    font-size: 1.125rem;
    color: #cc0000;
  }

  &Button {
    width: 110px !important;
    margin-top: 20px;
    margin-right: 50px;
    background: indigo;
    border-color: indigo;
    border-radius: 4px;

    &:hover {
      background: rgba(indigo, 0.7);
      border-color: rgba(indigo, 0.7);
    }
    &:active {
      background: indigo !important;
      border-color: indigo !important;
    }
  }
}
